import React from 'react'

import './PoshPolicy.scss'

const PoshPolicy = () => {
  return (
    <>
      <div className='main-content'>
        <div className='title'> POSH Policy </div>

        <p><span className='sub-heading'> 1. Policy Statement </span></p>
        <p> SOLV registered as Standard Chartered Research and Technology India Private Limited
          (henceforth referred to as “the Company”) is committed to creating and maintaining a working
          environment which is cooperative, pleasant and free from any type of harassment. Sexual
          Harassment by an employee to another employee will not be permitted, tolerated or condoned.
          This policy is deemed to be incorporated in the service conditions of all employees and comes
          into effect immediately. </p>
        <p><span className='sub-heading'>2.	Objective</span> </p>
        <p>
          The Policy aims at evolving a permanent mechanism for prevention, prohibition and redressal of
          sexual harassment of any nature by an employee to another employee at the Workplace. To
          sensitize the employees about the recognition of the rights of the other employees and their
          obligation towards the fellow employees to treat them with dignity and self-respect and to make
          them aware that Company is determined to promote working environment in which persons of
          both the genders complement each other as equals. To ensure complete compliance with the
          provisions of the Sexual Harassment of Women at Workplace (Prevention, Prohibition and
          Redressal) Act, 2013 and the Rules thereunder and uphold the commitment of the Company to
          provide an environment free of discrimination of any nature of employees. The Policy is Gender
          Neutral.
          <b><i>The aim of the policy is not only to address the complaint by a female employee
            against male employee but would also apply to a complaint of sexual harassment from
            male against female employee. SOLV follows a Zero Tolerance to sexual harassment at
            workplace.
          </i></b>
        </p>

        <p><span className='sub-heading'> 3. Eligibility </span></p>
        <p>This policy shall apply to all the employees of the Company including trainees, probationers,
          temporary and contractual staff and others who are engaged through Vendors and Service
          Providers. This policy shall not only cover all types of sexual harassment at the Workplace, but
          also covers any conduct involving employees outside the Workplace including while travelling in
          the Company provided transport, business trips, off site training programs, social gathering of
          employees organized by the Company etc.
        </p>

        <p><span className='sub-heading'> 4. Definitions </span></p>
        <ul>
          <li>
            <b>Complainant /Aggrieved Employee:</b>Refers to any employee who has lodged a complaint of sexual harassment at Workplace.
          </li>
          <li>
            <b>Respondent Employee:</b> Refers to any employee against whom the complaint of sexual harassment has been lodged.
          </li>
          <li>
            <b> Workplace: </b>  : Includes all the offices of the Company and departments, branches situated
            anywhere in India. It also includes any place visited by the employees arising out of or
            during the course of employment including transportation provided by the Management of
            the establishment for undertaking the journey, the places where the employees of the
            Company have met for social, sports or extracurricular activities organized by the Company.
          </li>
          <li>
            <b>	Sexual Harassment: </b>  Includes any one or more of the following unwelcome acts or behaviour (whether directly or by implication) namely:

            <ul className='list-under-list'>
              <li>Physical contact and advances</li>
              <li>A demand or request for sexual favours</li>
              <li>
                Making sexually coloured remarks
              </li>
              <li>Showing pornography</li>
              <li>
                Any other unwelcome physical, verbal, or non-verbal conduct of sexual nature
              </li>
            </ul>

            <p>
              The following circumstances, among other circumstances, if it occurs or is present in relation to or connected with any act or behaviour amounts to sexual harassment: -
            </p>
            <ul className='list-under-list'>
              <li>
                Implied or explicit promise of preferential treatment in employment
              </li>
              <li>
                Implied or explicit threat of detrimental treatment in employment
              </li>
              <li>
                Implied or explicit threat about present or future employment status
              </li>
              <li>
                Interference with the work or creating an intimidating or offensive or hostile work environment for the employee
              </li>
              <li>
                Humiliating treatment likely to affect employee’s health or safety
              </li>
            </ul>


          </li>
          <li>
            <b>Internal Committee: </b>Internal Committee (IC) constituted under this policy and shall also
            be treated as Internal Compliance Committee (ICC) as envisaged Sexual Harassment of
            Women at Workplace (Prevention, Prohibition & Redressal) Act and Rules thereunder.
          </li>
          <li>
            <b>Disciplinary Authority: </b>Disciplinary Authority means, authority specified by the Company
            from time to time and notified for the information of the employees. The Company may
            appoint or specify different disciplinary authority for employees at different levels or grades.
          </li>
          <li>
            <b>Appellate Authority: </b> Appellate Authority means, authority specified by the Company from
            time to time and notified for the information of the employees. The Company may appoint
            or specify different Appellate Authority for employees at different levels or grades.
          </li>
        </ul>

        <p><span className='sub-heading'> 5. Process </span></p>
        <p>(i) INTERNAL COMMITTEE / INTERNAL COMPLIANCE COMMITTEE (ICC):
          Internal Compliance Committee (ICC) consists of the following: -
        </p>

        <ul>
          <li>
            A presiding officer who shall be a woman employed at a Senior level at workplace from among the employees.
          </li>
          <li>
            Not less than two members from amongst employees preferably committed to cause of the women or who had experience in social work or have a legal knowledge.
          </li>
          <li>
            One member from amongst non-governmental organization or association committed to the cause of women or a person familiar with the issues relating to harassment at workplace including sexual harassment.
          </li>
          <li>
            The management shall ensure that at least one half of the total members so nominated shall be women.
            <p>HR Team will notify the details of the Internal Compliance Committee (ICC) members constituted under this Policy to employees from time to time. </p>

            <p>
              The Internal Committee / Internal Compliance Committee (ICC) shall have the same powers as are vested in a Civil Court under the Code of Civil Procedure, 1908 (5 of 1908) when trying a suit in respect of the following matters, namely: </p>
            <p>(a) summoning and enforcing the attendance of any person and examining him on oath;</p>
            <p>(b) requiring the discovery and production of documents; and </p>
            <p>(c) any other matter which may be prescribed. </p>
            <p>Internal Compliance Committee (ICC) shall meet periodically and review the measures taken for creating awareness among the employees on gender sensitivity and suggest further measures for enhancing the level of sensitivity on the subject among the employees.
            </p>
          </li>
        </ul>

        <p>(ii) COMPLAINT PROCEDURE:</p>
        <p>Any Aggrieved Employee may make a complaint in writing, a complaint of harassment at workplace to the Internal Compliance Committee (ICC) giving the details of the harassment meted out to him or her within a period of 90 days from the date of the incident and in case of a series of incidents, within a period of 90 days from the date of last incident. The complaint by an aggrieved employee be sent either by post/email or given in person to the Presiding Officer of the Internal Compliance Committee (ICC) or to any other Member of the Committee. Employees may send a mail to posh@solvezy.com to raise a complaint.</p>
        <p>The Internal Compliance Committee (ICC) may, for the reasons to be recorded in writing, extend the time limit not exceeding 90 days, if it is satisfied that the circumstances were such which prevented the employee from filing a complaint within the said period.</p>
        <p>Where the employee is unable to make a complaint on account of his or her physical or mental incapacity or death or otherwise, his or her legal heir may make a complaint under this section. It is, however, pertinent to state that where the aggrieved employee is unable to make a complaint on account of his or her physical incapacity, a complaint may be filed by:</p>

        <p>a)	His/her relative or friend</p>
        <p>b)	His/her co-worker</p>
        <p>c)	any person who has knowledge of the incident, with the written consent of the aggrieved employee</p>

        <p>On receipt of the complaint the Internal Compliance Committee (ICC) shall investigate and inquire into the complaint in accordance with principles of natural justice. The enquiry shall be completed within a period of 90 days of receipt of the complaint.
          The parties shall not be allowed to bring any legal practitioner to represent them at any stage of the inquiry proceedings before the Internal Compliance Committee (ICC).
        </p>

        <p>(iii) IMPLEMENTATION OF THE RECOMMENDATIONS OF THE INTERNAL COMPLIANCE COMMITTEE:</p>
        <p>After completion of the inquiry, the Internal Compliance Committee (ICC) shall forward its report with its recommendations to the Disciplinary Authority. The Disciplinary Authority shall examine the report and thereafter award the punishment in accordance with the disciplinary policy of the Company which includes written apology, warning, reprimand or censure, withholding of promotion, withholding of pay rise or increments, terminating the services or undergoing counselling session or carrying out community service. The disciplinary action shall be completed within 60 days of the receipt of the recommendations of the Internal Compliance Committee (ICC).</p>

        <p>
          (iv) APPEAL:
        </p>
        <p>
          The Complainant or the Respondent if they are aggrieved by the Recommendations or Findings/Recommendations or the Orders of the Disciplinary Authority may prefer an Appeal before the Appellate Authority (as mentioned under the ICC list) within 90 days of the submission of the Findings by the Internal Compliance Committee. The Appeal shall be made by the aggrieved person in writing with three copies. The Appellate Authority shall dispose of the Appeal within 60 days.
        </p>

        <p>
          (v) MALICIOUS/FALSE COMPLAINT:
        </p>

        <p>Where the Internal Compliance Committee (ICC) arrives at a conclusion that the allegation against the Respondent is malicious, false or has produced any forged or misleading document, it may recommend to the employer to take action against the Complainant in accordance with the disciplinary policy of the Company. Further, the malicious intent on part of the Complainant needs to be established through the inquiry process before any action against such Complainant is recommended by the Internal Compliance Committee (ICC) of the Company.</p>
        <p>However, this shall not include complaints which are difficult to prove or have been made in good faith. Where the Internal Compliance Committee (ICC) arrives at a conclusion that during the inquiry any witness (including the Complainant and the Respondent) has given false evidence or produced any forged or misleading document, it may recommend to the employer of the witness to take action against such person. Penalty for publication or making known contents of complaint and inquiry proceedings: Where any person, entrusted with the duty to handle or deal with the complaint, inquiry or any recommendations or action, publishes or makes known the contents of complaint or inquiry proceedings, he/she shall be liable for penalty in accordance with any breach of confidentiality the person concerned shall be liable for penalty in accordance with the provisions of service rules applicable to the said person or with penalty of Rs. 5000/- which may be recovered from the salary.</p>

        <p>(vi) NON-RETALIATION:</p>
        <p>SOLV will not accept, support, or tolerate, retaliation in any form against any Employee who, acting in good faith, reports suspected misconduct, asks questions or raises concerns. Any person who engages in such retaliation directly or indirectly, or encourages others to do so, may be subject to appropriate disciplinary action. Retaliation will be treated as a misconduct. Retaliation against those reporting Sexual Harassment is prohibited by this policy. Retaliation means and includes any hurtful employment action against an individual. Anyone suspecting or experiencing retaliation should report to the appropriate authorities. Anyone feeling that a retaliation complaint did not get a prompt response can contact Head of HR directly. Retaliation cases are treated as seriously as an alleged case of Sexual Harassment even if the original Sexual Harassment Complaint is not proven.</p>



        <p><span className='sub-heading'> 6. Employee Obligation and Conduct </span></p>
        <p>
          All Employees are expected to conduct themselves in accordance with this policy both in letter
          and spirit. Every Employee is hereby called upon to ensure and uphold the dignity, freedom and
          respect of each individual and support the Company’s endeavour to ensure this. An Employee
          who notices any kind of harassment against any co-employee or receives any information in good
          faith about any kind of harassment at the Workplace is also required to report such incidents to
          a superior or to HR or any member of the committee constituted hereunder for further
          investigation and action. Any violation of this policy by any employee whether it is directed toward
          a co-employee, vendor, customer, business partner, visitor or shareowner will result in
          disciplinary action against such employee in the manner set out in this Policy.
        </p>
        <p>
          The Internal Compliance Committee, Disciplinary Authority and Appellate Authority shall ensure
          complete compliance principles of natural justice and provisions of law. The Company may make
          any alteration or amendment or rescind any clauses of this policy as and when it finds necessary
          to ensure complete compliance to the provisions of the applicable law and to ensure that the
          policy completely serves the purpose for which it has been laid down.
        </p>

        <p><span className='sub-heading'> 7. Obligations of the Management </span></p>
        <p>
          The Management will also initiate action under the Indian Penal Code or any other law for the
          time being in force, against a perpetrator, where the perpetrator is not an employee of the
          establishment and there is complaint of Sexual Harassment against the said perpetrator in the
          Workplace where the harassment took place. The Management will create awareness amongst
          employee on gender sensitivity and harassment free Workplace.
        </p>

        <p><span className='sub-heading'> 8. Confidentiality & Non-Disclosure </span></p>
        <p>
          The strictest confidentiality will be observed, therefore restricting all information generated to
          the smallest possible group. The identity and address of the Aggrieved Employee, Respondent
          and witnesses, any information relating to conciliation and enquiry proceedings,
          recommendations of the Internal Compliance Committee, the action taken by the employer shall
          be treated as confidential and shall not be disclose to anyone who is not authorized to receive
          the said information. Any attempt by the members of the POSH Committee or the witnesses or
          any other persons involved in the inquiry to discuss or disclose this information to anyone except
          those directly involved with the Complaint will be treated with disciplinary action. This information
          will not be disclosed even to members of senior management who are not directly involved with
          the Complaint.
        </p>

        <p><span className='sub-heading'> 9. Disclaimer </span></p>
        <ul>
          <li>
            This Policy is in accordance to the Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013 – Government of India.
          </li>
          <li>
            The policy and procedures stated in this document will be interpreted, administered, amended and maintained by the Company at its sole discretion.
          </li>
          <li>
            The Company reserves its unconditional right to withdraw or amend, the whole or part of the policy at any time as it may deem fit, with/without any notice to the employee.
          </li>
          <li>
            The policy shall be reviewed for ensuring its adequacy, completeness and absence of any ambiguity by the HR Periodically.
          </li>
          <li>
            Any revision / amendments desired in the policy shall be announced after HR Head Approval.
          </li>
        </ul>

        <p><span className='sub-heading'> 10. ICC </span></p>
        <p>
          List of Members of the Internal Compliance Committee, with contact details:
        </p>
        <div>
          <table style={{ width: "100%;", marginTop: "2vw" }}>
            <thead>
              <tr>
                <th>
                  NAME &nbsp;<br></br>
                </th>
                <th>
                  EMAIL&nbsp;<br></br>
                </th>
                <th>
                  NUMBER &nbsp;<br></br>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "20.0000%;" }}>
                  Chairperson/Presiding Officer: Priti Mittal<br></br>
                </td>
                <td style={{ width: "30.0000%;" }}>
                  <a className='mail-link' href="mailto:posh@solvezy.com">posh@solvezy.com</a>
                </td>
                <td style={{ width: "50.0000%;" }}>
                  9873919230
                  <br></br>
                </td>
              </tr>
              <tr>
                <td >
                  Member: Jui S Karandikar<br></br>
                </td>
                <td>
                  <a className='mail-link' href="mailto:posh@solvezy.com">posh@solvezy.com</a>
                </td>
                <td >
                  9999566774
                  <br></br>
                </td>
              </tr>
              <tr>
                <td style={{ width: "20.0000%;" }}>
                  Member: Sreedevi S<br></br>
                </td>
                <td style={{ width: "30.0000%;" }}>
                  <a className='mail-link' href="mailto:posh@solvezy.com">posh@solvezy.com</a>
                </td>
                <td style={{ width: "50.0000%;" }}>
                  9686508370
                  <br></br>
                </td>
              </tr>

              <tr>
                <td style={{ width: "20.0000%;" }}>
                  Member: Loveleen Malhotra<br></br>
                </td>
                <td style={{ width: "30.0000%;" }}>
                  <a className='mail-link' href="mailto:posh@solvezy.com">posh@solvezy.com</a>
                </td>
                <td style={{ width: "50.0000%;" }}>
                  9716199206
                  <br></br>
                </td>
              </tr>

              <tr>
                <td style={{ width: "20.0000%;" }}>
                  Member: Akash Srivastava<br></br>
                </td>
                <td style={{ width: "30.0000%;" }}>
                  <a className='mail-link' href="mailto:posh@solvezy.com">posh@solvezy.com</a>
                </td>
                <td style={{ width: "50.0000%;" }}>
                  9810618429
                  <br></br>
                </td>
              </tr>

              <tr>
                <td style={{ width: "20.0000%;" }}>
                  Member: Khusboo Agarwal <br></br>
                </td>
                <td style={{ width: "30.0000%;" }}>
                  <a className='mail-link' href="mailto:posh@solvezy.com">posh@solvezy.com</a>
                </td>
                <td style={{ width: "50.0000%;" }}>
                  8884024824
                  <br></br>
                </td>
              </tr>

              <tr>
                <td style={{ width: "20.0000%;" }}>
                  External Member: Yamuna (Sasha)<br></br>
                </td>
                <td style={{ width: "30.0000%;" }}>
                  <a className='mail-link' href="mailto:yamuna@sashaindia.com">yamuna@sashaindia.com</a>

                </td>
                <td style={{ width: "50.0000%;" }}>
                  9845569732
                  <br></br>
                </td>
              </tr>

            </tbody>
          </table>
        </div>

        <p><span className='sub-heading'> FAQ’s: </span></p>
        <p>Types of Sexual Harassment at workplace: </p>
        <p>
          Sexual Harassment at workplace can be categorized into two groups: quid pro quo and hostile
          environment. Examples of Quid Pro Quo Sexual Harassment: A manager informs an associate
          that he/she would lose their job if he/she does not give into his/her sexual advances. A
          manager informs an Associate that he/she would get a better rating in the appraisal or a
          promotion if he/she gives into his/her sexual advances. A manager promises an Associate a
          particular project or a role in exchange of sexual favours. Hostile environment sexual
          harassment occurs when either speech or conduct of a sexual nature takes place and is seen
          or perceived as offensive and interferes with the work performance of the recipient, or any
          one or more associates. Hostile environment sexual harassment may also include intimidating
          or harassing conduct that is directed at an individual, or a group of individuals.
        </p>


      </div>
    </>
  )
}

export default PoshPolicy
